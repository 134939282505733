<template>
  <div class="about">
    <TopNav></TopNav>
    <div class="first">
      <div class="top-tab">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="联系我们" name="first"></el-tab-pane>
          <el-tab-pane label="加入我们" name="second"></el-tab-pane>
          <el-tab-pane label="员工发展" name="third"></el-tab-pane>
        </el-tabs>
        <div class="top-tit">联系我们</div>
      </div>
    </div>
    <Cont v-show="index === '0'"></Cont>
    <Add v-if="index === '1'"></Add>
    <Company v-if="index === '2'"></Company>

    <Footer></Footer>
  </div>
</template>

<script>
  import TopNav from "@/components/Nav/TopNav.vue";
  import Footer from "@/components/Footer/Footer.vue";
  import Cont from "@/components/cont.vue";
  import Add from "@/components/add.vue";
  import Company from "@/components/company.vue";

  export default {
    data() {
      return {
        activeName: 'first',
        formData: {
          name: "",
          phone: "",
          email: "",
          content: ""
        },
        isLoading: false,
        index:'0'
      };
    },
    components: {
      TopNav,
      Footer,
      Cont,
      Add,
      Company
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
        this.index = tab.index
        // console.log(this.index)
      },
      submitFn() {
        if (this.isLoading) {
          return;
        }
        let Util = new this.$Util();
        if (!Util.checkName(this.formData.name)) {
          this.$message.error("姓名填写有误，请正确填写后再次提交！");
          return;
        }
        if (!Util.checkPhone(this.formData.phone)) {
          this.$message.error("手机号填写有误，请正确填写后再次提交！");
          return;
        }
        if (this.formData.email) {
          if (!Util.checkMail(this.formData.email)) {
            this.$message.error("邮箱填写有误，请正确填写后再次提交！");
            return;
          }
        }
        if (!this.formData.content) {
          this.$message.error("请检查留言内容是否填写！");
          return;
        }
        this.isLoading = true;
        this.$Api
          .addMsg(this.formData)
          .then(res => {
            console.log(res);
            this.isLoading = false;
            if (res.code == 200) {
              this.$message.success("提交成功");
              this.formData = {};
            }
          })
          .catch(err => {
            this.isLoading = false;
          });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .about {
    .first {
      padding-top: 10px;
      width: 100%;
      position: absolute;
      .top-tab{
        width: 1200px;
        margin: 0 auto;
        color: white;
      }
      .top-tit{
        color: white;
        font-size: 16px;
        text-align: left;
        width: 150px;
        position: relative;
        top: -50px;
        left: 0;
      }

      /deep/ .el-tabs__nav-wrap::after{

        height: 1px !important;
        background-color: #E4E7ED;
      }
      /deep/ .el-tabs__nav{
        padding-left: 860px;
      }
      /deep/ .el-tabs__active-bar{
        margin-left: 860px;

      }
      /deep/ .el-tabs__item{
        color: white;
        font-size: 16px;
        height: 46px;
        padding: 0 20px;
      }
      /deep/.el-tabs__active-bar{
        background-color: #fff !important;
        height: 3px;
      }
      /deep/.is-active{
        font-weight: 550;
        font-size: 16px;
      }

    }
    .fourth {
      width: 100%;
      height: 800px;
      padding-top: 100px;
      margin-top: 60px;
      background: url("./../static/img/contact/bg.png") no-repeat center;
      background-size: cover;
      .fy_center_box {
        padding-top: 44px;
        text-align: center;

        .title {
          font-size: 42px;
          color: #fff;
          font-weight: bold;
          letter-spacing: 4px;
        }

        .under_tit {
          padding-top: 16px;
          font-size: 28px;
          color: #b6b6c6;
        }

        .fourth_bottom {
          width: 100%;
          margin-top: 96px;

          .left {
            text-align: left;
            margin-right: 66px;
            float: left;
          }

          .des {
            margin-top: 20px;
            overflow: hidden;
            clear: both;
            color: white;

            img {
              width: 82px;
              height: 82px;
              float: left;
            }

            p {
              color: #fff;
              font-size: 16px;
              text-align: left;
              padding-left: 102px;
              line-height: 30px;

              &:first-of-type {
                padding-top: 14px;
              }
            }
            .c-phone{
              font-size: 24px;
            }
          }

          .logo {
            //width: 404px;
            //height: 134px;
            margin-left: 52px;
            margin-bottom: 50px;
          }

          .location_img {
            width: 600px;
            height: 404px;
          }
        }
      }
    }


    .fifth {
      background: #ededed;
      padding-top: 72px;
      padding-bottom: 80px;
      text-align: center;

      .title {
        color: #191e4a;
        font-size: 30px;
        letter-spacing: 4px;
      }

      .under_tit {
        color: #191e4a;
        font-size: 18px;
        padding-top: 8px;
      }
      .line-o{
        width:52px;
        height:2px;
        margin: 0 auto;
        background-color: #C39556;
        margin-top: 20px;
      }

      label {
        position: relative;
        color: #666666;
        font-size: 18px;
        padding-right: 2px;

        &.required {
          &:after {
            position: absolute;
            right: 4px;
            content: "*";
            color: #f56c6c;
          }
        }
      }

      .form_box {
        padding: 0 60px;

        .top_box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 20px;
          margin-top: 60px;

          .input_box {
            display: flex;
            line-height: 42px;

            input {
              height: 100%;
              font-size: 18px;
              line-height: 42px;
              height: 42px;
              padding-left: 2em;
            }
          }
        }

        .center_box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 240px;

          textarea {
            padding-top: 10px;
            width: 1024px;
            height: 100%;
            border-radius: 4px;
            font-size: 18px;
            line-height: 20px;
            text-indent: 2em;
          }
        }
        .submit-button{
          width: 194px;
          height: 60px;
          background-image: linear-gradient(to right,#DEBB8A,#BE8E4C);
          line-height: 60px;
          border-radius: 30px;
          font-size: 22px;
          color: white;
          margin: 0 auto;
          margin-top: 40px;

        }
      }
    }
  }
  .node-img1{
    width: 100%;
    height: 30px;
    text-align: center;
    position: relative;
    top: 10px;

  }
  .node-img2{
    width: 100%;
    height: 30px;
    text-align: center;
    position: relative;
    top: -22px;

  }
</style>
