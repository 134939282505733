<template>
  <div class="about">
    <div class="fourth">
      <img class="animated fadeInDown" style="margin-top: 50px" src="../static/img/contact/icon.png" height="79" width="292"/>
    </div>
    <div class="fifth">
      <div class="fy_center_box">
        <div class="nav_box">
          <div :class="['nav_item','nav_item1',currentIndex==1?'active':'']" @click="changeNav(1)">社会招聘</div>
          <div :class="['nav_item',currentIndex==0?'active':'']" @click="changeNav(0)">校园招聘</div>
        </div>

      </div>
      <div class="add" v-if="currentIndex==1">
        <div style="margin-bottom: 40px" v-for="(item,i) in list">
          <el-card shadow="always">
            <div class="add-item" >
              <div>
                <img :src="item.img" height="220" width="300"/>
              </div>
              <div class="add-item-r">
                <div class="add-item-r1" v-for="(p,q) in item.worker" @click="recruitment(p.url)">
                  <div class="add-item-r1-name">{{ p.title }}</div>
                  <div class="add-item-r1-pos">工作地点：{{ p.place }}</div>
                  <div class="add-item-r1-num">招聘人数：{{ p.num }}人</div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <div v-if="currentIndex==0" class="school">
        <p class="tit">2021校园招聘已启动</p>
        <div class="school-tab">
          <el-card shadow="always" body-style="padding:0px;" style="margin:0 20px">
            <el-table :data="tableData" min-height="100%"
                      :header-cell-style="{background:'#AE7B40',padding:'10px',color:'#fff',fontsize:'16px'}">
              <el-table-column align="center" v-for="(column,index) in columns" :key="index" :prop="column.prop"
                               :label="column.label" :width="column.width" :sortable="column.sort" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </el-card>
          <div class="bot">

            <p>（1）2021年校园招聘火热进行中，具体招聘时间，请关注各高校就业网通知。</p>
            <p>（2）如无法参加招聘会，可投递简历至：csc@jingfang.net。</p>
          </div>
        </div>




      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        activeName: 'first',
        isLoading: false,
        currentIndex: 1,
        index:'0',
        columns:[
          {prop: 'pos', label: '城市', sort: false, width: 150},
          {prop: 'schools', label: '学校', sort: false},
          {prop: 'time', label: '预计招聘日期', sort: false, width: 150},

        ],
        tableData:[
          {pos:'北京',schools:'北京中医药大学',time:'10月底'},
          {pos:'广州',schools:'广州中医药大学',time:'7月初'},
          {pos:'上海',schools:'上海中医药大学',time:'6月初'},
          {pos:'南京',schools:'南京中医药大学',time:'5月底'},
          {pos:'成都',schools:'成都中医药大学',time:'4月初'},
          {pos:'济南',schools:'山东中医药大学',time:'3月初'},
          {pos:'哈尔滨',schools:'黑龙江中医药大学',time:'2月底'},
          {pos:'天津',schools:'天津中医药大学',time:'1月初'},
          {pos:'长沙',schools:'湖南中医药大学',time:'6月中'},
          {pos:'沈阳',schools:'辽宁中医药大学',time:'5月初'},
          {pos:'郑州',schools:'河南中医药大学',time:'4月底'},
          {pos:'北京',schools:'清华大学',time:'3月中'},
          {pos:'北京',schools:'北京航空航天大学',time:'2月底'},
          {pos:'北京',schools:'中国科学院大学',time:'1月底'},
          {pos:'天津',schools:'天津大学',time:'10月初'},
          {pos:'杭州',schools:'浙江大学',time:'11月底'},
          {pos:'杭州',schools:'杭州电子科技大学',time:'8月中'},
          {pos:'合肥',schools:'中国科学技术大学',time:'7月初'},
          {pos:'南京',schools:'南京邮电大学',time:'6月底'},
          {pos:'深圳',schools:'深圳大学',time:'5月初'},
          {pos:'上海',schools:'上海科技大学',time:'4月中'},
          {pos:'武汉',schools:'华中科技大',time:'3月底'},
        ],
        list:[
          {img:require("@/static/img/contact/add1.png"),worker:[]},
          {img:require("@/static/img/contact/add2.png"),worker:[]},
          {img:require("@/static/img/contact/add3.png"),worker:[]},
          {img:require("@/static/img/contact/add4.png"),worker:[]},

        ]

      };
    },
    mounted() {
      this.changeNav(1)
      this.$Api.employList().then(res => {
        this.evList = res.data
        this.list[0].worker = res.data.研发团队?res.data.研发团队:[]
        this.list[1].worker = res.data.营销团队?res.data.营销团队:[]
        this.list[2].worker = res.data.数据运营?res.data.数据运营:[]
        this.list[3].worker = res.data.行政管理?res.data.行政管理:[]
      }).catch(error => {
        this.isLoading = false
      })
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
        this.index = tab.index
        console.log(this.index)
      },
      changeNav(index) {
        this.currentIndex = index
      },
      recruitment(herf){
        if (herf) {
          window.open(herf)
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .about {
    .fourth {
      width: 100%;
      height: 360px;
      padding-top: 100px;
      margin-top: 80px;
      background: url("./../static/img/contact/con-bg.png") no-repeat center;
      background-size: cover;
    }
    .fifth {
      background: #fff;
      padding-top: 72px;
      padding-bottom: 20px;
      text-align: center;
      .nav_box {
        height: 60px;
        line-height: 58px;
        font-size: 18px;
        text-align: left;
        margin-bottom: 30px;
        display: inline-block;
        .nav_item1{
          margin-right: 120px;
        }
        .nav_item {
          display: inline-block;
          width: 190px;
          height: 100%;
          line-height: 60px;
          font-size: 18px;
          text-align: center;
          color: #AB7D3E;
          border-radius: 30px;

          background-image: url("./../static/img/product/p-bg1.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          //border-radius: 30px;
          &:hover {
            cursor: pointer;
          }
          &.active {
            background-image: url("./../static/img/product/p-bg2.png");
            color: #fff;
          }
        }

      }
      .add{
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        .add-item{
          width: 1200px;
          display: flex;
          justify-content: left;
          align-items: first;
          .add-item-r{
            //width: 800px;
            margin-left: 39px;
            display: flex;
            flex-wrap: wrap;
            .add-item-r1{
              width: 270px;
              height: 110px;
              cursor: pointer;
              padding-top: 16px;
              .add-item-r1-name{
                font-size: 20px;
                color: #333333;
                font-weight: 550;
              }
              .add-item-r1-pos{
                font-size: 16px;
                margin-top: 10px;
                margin-bottom: 5px;
                color: #666666;

              }
              .add-item-r1-num{
                font-size: 16px;
                color: #666666;
              }
            }
          }
        }
      }
      .school{
        .tit{
          color: #AE7B40;
          font-size: 24px;
          font-weight: 550;
          margin-bottom: 20px;
        }
        .school-tab{
          width: 1200px;
          margin: 0 auto;
          padding-bottom: 70px;
        }
        .bot{
          text-align: left;
          font-size: 12px;
          color: #999;
          margin-top: 20px;
          padding-left: 20px;
        }
      }
    }

  }
</style>
