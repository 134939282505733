<template>
  <div class="about">
    <div class="fourth">
      <div class="fy_center_box">
        <img class="animated fadeInDown" src="../static/img/contact/tit.png" height="79" width="292"/>
        <div class="fourth_bottom">
          <div class="left">
            <img class="logo" src="@/static/img/home/logo-foot2.png"/>
<!--            <img class="logo" src="@/static/img/contact/logo.png"/>-->
            <div class="des">
              <img src="../static/img/contact/icon1.png" height="100" width="100"/>
              <p class="c-phone">0371-60523888</p>
              <p>7*24小时</p>
            </div>
            <div class="des">
              <img src="../static/img/contact/icon2.png" height="100" width="100"/>
              <p>郑州市高新技术产业开发区</p>
              <p>天健湖智联网产业园3号楼13层</p>
            </div>
          </div>
<!--          pos.png-->
          <img class="location_img" src="@/static/img/contact/address-new.png"/>
        </div>
      </div>
    </div>
    <div class="fifth">
      <div class="fy_center_box">
        <p class="title">获取更多资讯</p>
        <p class="under_tit">请留下您的联系方式</p>
        <div class="line-o"></div>
        <div class="form_box">
          <div class="top_box">
            <div class="input_box">
              <label class="required" for>姓名：</label>
              <input type="text" v-model="formData.name"/>
            </div>
            <div class="input_box">
              <label class="required" for>电话：</label>
              <input type="number" v-model="formData.phone"/>
            </div>
            <div class="input_box">
              <label for>邮箱：</label>
              <input type="text" v-model="formData.email"/>
            </div>
          </div>
          <div class="center_box">
            <label class="required" for>留言：</label>
            <textarea cols="30" id name rows="10" style="resize: none;" v-model="formData.content"></textarea>
          </div>
          <div @click="submitFn" class="submit-button"> 提 交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      activeName: 'first',
      formData: {
        name: "",
        phone: "",
        email: "",
        content: ""
      },
      isLoading: false,
      index: '0'
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
      this.index = tab.index
      console.log(this.index)
    },
    submitFn() {
      if (this.isLoading) {
        return;
      }
      let Util = new this.$Util();
      if (!Util.checkName(this.formData.name)) {
        this.$message.error("姓名填写有误，请正确填写后再次提交！");
        return;
      }
      if (!Util.checkPhone(this.formData.phone)) {
        this.$message.error("手机号填写有误，请正确填写后再次提交！");
        return;
      }
      if (this.formData.email) {
        if (!Util.checkMail(this.formData.email)) {
          this.$message.error("邮箱填写有误，请正确填写后再次提交！");
          return;
        }
      }
      if (!this.formData.content) {
        this.$message.error("请检查留言内容是否填写！");
        return;
      }
      this.isLoading = true;
      this.$Api
          .addMsg(this.formData)
          .then(res => {
            this.isLoading = false;
            this.$message.success("提交成功");
            this.formData = {};
          })
          .catch(err => {
            this.isLoading = false;
          });
    }
  },
};
</script>

<style lang="scss" scoped>
.about {
  .first {
    padding-top: 10px;
    width: 100%;
    position: absolute;

    .top-tab {
      width: 1200px;
      margin: 0 auto;
      color: white;
    }

    .top-tit {
      color: white;
      font-size: 16px;
      text-align: left;
      width: 150px;
      position: relative;
      top: -50px;
      left: 0;
    }

    /deep/ .el-tabs__nav-wrap::after {

      height: 1px !important;
      background-color: #E4E7ED;
    }

    /deep/ .el-tabs__nav {
      padding-left: 860px;
    }

    /deep/ .el-tabs__active-bar {
      margin-left: 860px;

    }

    /deep/ .el-tabs__item {
      color: white;
      font-size: 16px;
      height: 46px;
      padding: 0 20px;
    }

    /deep/ .el-tabs__active-bar {
      background-color: #fff !important;
      height: 3px;
    }

    /deep/ .is-active {
      font-weight: 550;
      font-size: 16px;
    }

  }

  .fourth {
    width: 100%;
    height: 800px;
    padding-top: 100px;
    margin-top: 80px;
    background: url("./../static/img/contact/bg.png") no-repeat center;
    background-size: cover;

    .fy_center_box {
      padding-top: 44px;
      text-align: center;

      .title {
        font-size: 42px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 4px;
      }

      .under_tit {
        padding-top: 16px;
        font-size: 28px;
        color: #b6b6c6;
      }

      .fourth_bottom {
        width: 100%;
        margin-top: 96px;

        .left {
          text-align: left;
          margin-right: 66px;
          float: left;
        }

        .des {
          margin-top: 20px;
          overflow: hidden;
          clear: both;
          color: white;

          img {
            width: 82px;
            height: 82px;
            float: left;
          }

          p {
            color: #fff;
            font-size: 16px;
            text-align: left;
            padding-left: 102px;
            line-height: 30px;

            &:first-of-type {
              padding-top: 14px;
            }
          }

          .c-phone {
            font-size: 24px;
          }
        }

        .logo {
          //width: 404px;
          //height: 134px;
          margin-left: 52px;
          margin-bottom: 50px;
        }

        .location_img {
          width: 710px;
          height: 400px;
        }
      }
    }
  }


  .fifth {
    background: #ededed;
    padding-top: 72px;
    padding-bottom: 80px;
    text-align: center;

    .title {
      color: #191e4a;
      font-size: 30px;
      letter-spacing: 4px;
    }

    .under_tit {
      color: #191e4a;
      font-size: 18px;
      padding-top: 8px;
    }

    .line-o {
      width: 52px;
      height: 2px;
      margin: 0 auto;
      background-color: #C39556;
      margin-top: 20px;
    }

    label {
      position: relative;
      color: #666666;
      font-size: 18px;
      padding-right: 2px;

      &.required {
        &:after {
          position: absolute;
          right: 60px;
          content: "*";
          color: #ff0000;
        }
      }
    }

    .form_box {
      padding: 0 60px;

      .top_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 60px;

        .input_box {
          display: flex;
          line-height: 42px;

          input {
            height: 100%;
            font-size: 18px;
            line-height: 42px;
            height: 42px;
            padding-left: 2em;
          }
        }
      }

      .center_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 240px;

        textarea {
          padding-top: 10px;
          width: 1024px;
          height: 100%;
          border-radius: 4px;
          font-size: 18px;
          line-height: 20px;
          text-indent: 2em;
        }
      }

      .submit-button {
        width: 194px;
        height: 60px;
        background-image: linear-gradient(to right, #DEBB8A, #BE8E4C);
        line-height: 60px;
        border-radius: 30px;
        font-size: 22px;
        color: white;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 40px;

      }
    }
  }
}

.node-img1 {
  width: 100%;
  height: 30px;
  text-align: center;
  position: relative;
  top: 10px;

}

.node-img2 {
  width: 100%;
  height: 30px;
  text-align: center;
  position: relative;
  top: -22px;

}
</style>
