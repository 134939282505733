<template>
  <div class="about">
    <div class="fourth">
      <img class="animated fadeInDown" style="margin-top: 50px" src="../static/img/contact/com-icon-3.png" height="79" width="292"/>
    </div>
    <div class="know">
      <div class="know1">
        <img class="animated fadeIn" src="../static/img/contact/com-icon-2.png" height="76" width="254"/>
      </div>
      <div class="know2">
        <img src="../static/img/contact/jfy-img.png" height="730" width="1209"/>
      </div>
      <div class="know3">
        <img v-show="scroll1" ref="scrolla" class="animated swing" src="../static/img/contact/com-icon-1.png" height="76" width="248"/>
      </div>
      <div class="down">
        <div class="fy_center_box second_top">
          <div class="row">
            <div
                :class="['top_btn',currentIndex==index?'active':'']"
                v-for="(item,index) in personList"
                :key="index"
                @mousemove="changeIndex(index)"
            >{{item.name}}</div>
          </div>
        </div>
        <div v-if="currentIndex==0&&isChange">
          <div class="slideshow">
            <div class="slideshow-top">国家高新技术开发区</div>
            <div class="slideshow-top slideshow-tops">先进的信息化办公平台和软硬件设施</div>
            <el-carousel :interval="4000" type="card" height="600px">
              <el-carousel-item>
                <div class="carousel1" style="width: 900px;height: 600px;background-color: #AE7B40"></div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="carousel2" style="width: 900px;height: 600px;background-color: #ccc"></div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="carousel3" style="width: 900px;height: 600px;background-color: #ff5600"></div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="carousel4" style="width: 900px;height: 600px;background-color: #ffdddd"></div>
                <!--                <img src="../static/img/scheme/scheme-bg1.jpg" height="700" width="900"/>-->
              </el-carousel-item>
            </el-carousel>
          </div>


        </div>
        <div v-show="currentIndex==1&&isChange">
          <div class="down-m">

            <div class="down-m2 animated fadeInLeft">
              <div class="down-m2-tit">
                用"薪"驱动人才，用"酬"吸引人才
                对内具有公平性，对外具有竞争力
              </div>
              <div class="down-m2-val">将根据员工的个人能力及工作表现等定期进行考核评估予以调整，以绩效为导向，优先向价值创造者、贡献者和奋斗者倾斜。</div>
              <div class="down-m2-val">激励员工最大程度的“为客户创造价值”，进而实现个人价值回报的最大化。</div>
              <div class="down-m2-val">各职级薪资均由基本薪资+补贴+绩效奖金构成，递增部分全部列为绩效金。</div>
              <div class="down-m2-val">经方云会在以激励员工的前提下，根据市场薪资状况及公司经营状况调整薪资递增额度以及基准薪资，并进行公示公布。</div>
            </div>
            <div class="down-m1 animated fadeInRight">
              <img src="../static/img/contact/com-img-6.png" height="556" width="600"/>
            </div>
          </div>

        </div>
        <div v-show="currentIndex==2&&isChange">
          <div class="down-m3 animated fadeInLeft">以奋斗者为本的“个人双职业发展通道”</div>


          <img class="animated fadeInRight" src="../static/img/contact/com-img-5.png" height="447" width="1141"/>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        personList: [
          {
            name: "工作环境",
            tit:'国家政策引导释放巨大的市场需求',
            des: [
              {val:'围绕着2019年《意见》提出的全覆盖的重大目标， “到2022年，基本实现县办中医医疗机构全覆盖，力争实现全部社区卫生服务中心和乡镇卫生院设置中医馆、配备中医医师”根据国家相关部门2019年统计数据，全国2844个县级行政区，14677个乡，19531个镇、691510社区卫生服务中心（街道办事处和行政村），将直接激发千亿的市场份额，经方云人工智能辅助诊疗系统将快速实现市场布局，同时还会间接带动相关科技的发展。'},
              {val:'实现中医健康服务与互联网技术的整合，同时建成省级和国家级标准化的中医药大数据中心，为中医药传承发展提供科学数据支撑。'},
              {val:'现代化人工智能应用到中医药领域，提高医生的工作效率。同时也能助力中医人才培养模式改革，为中医药师生提供科学优质的学习平台。'},
            ]
          },
          {
            name: "薪酬福利",
            tit:'与环境和谐共处',
            des: [
              {val:'经方云遵循“诚信、和谐、开放、共赢”核心价值观，坚持节能环保、环境保护与可持续发展。'}
            ]
          },
          {
            name: "培训发展",
            tit:'社会公益',
            des: [
              {val:'企业依靠社会发展起来，也要反过来帮助社会发展。作为一个具有强烈社会责任感的公司，经方云认真履行企业公民责任，积极支援灾区重建，热心参与扶贫济困、安老助孤、帮困助学等慈善事业，以己所能及之力回馈社会。'}
            ]
          }
        ],
        currentIndex: 0,
        isChange: true,
        scroll1: false
      };
    },
    mounted() {
      window.addEventListener('scroll',this.handleScroll,true)
    },
    methods: {
      changeIndex(index) {
        // if (this.isChange) {
        // return;
        // }
        this.currentIndex = index;
      },
      handleScroll(){
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        // console.log(scrollTop)
        if (scrollTop >= 500 && !this.scroll1) {
          this.scroll1 = true
          this.$refs.scrolla.classList.add('animated', 'swing')
        }
      },
    },
    beforeDestroy() {
      window.removeEventListener('scroll',this.handleScroll,true)
    },
    watch: {
      currentIndex(val) {
        this.isChange = true;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .about {
    .fourth {
      width: 100%;
      height: 360px;
      padding-top: 100px;
      margin-top: 80px;
      background: url("./../static/img/contact/con-bg.png") no-repeat center;
      background-size: cover;
    }
    .know{
      width: 100%;
      height: 2000px;
      padding-top: 51px;
      .know2{
        margin: 50px 0;
      }
      .know3{
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .down {
        position: relative;
        padding-top: 20px;
        padding-bottom: 80px;
        height: 870px;
        text-align: center;
        overflow: hidden;
        .second_top {
          position: relative;
          height: 60px;
          margin-top: 60px;
          margin-bottom: 40px;
          z-index: 9;
          .row {
            display: flex;
            justify-content: space-between;
            width: 640px;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;
            .top_btn {
              display: inline-block;
              width: 190px;
              height: 100%;
              line-height: 60px;
              font-size: 18px;
              text-align: center;
              color: #AB7D3E;
              background-image: url("./../static/img/product/p-bg1.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              //background-color: #666666;
              &:hover {
                cursor: pointer;
              }
            }
            .top_btn.active {
              background-image: url("./../static/img/product/p-bg2.png");
              color: #fff;
            }
          }
        }
        .down-m{
          display: flex;
          justify-content: left;
          align-items: first;
          width: 1200px;
          margin: 0 auto;
          .down-m1{
            width: 600px;
          }
          .down-m2{
            width: 600px;
            text-align: left;
            .down-m2-tit{
              padding: 70px 120px 40px 120px;
              font-size: 22px;
              font-weight: 550;
              color: #333333;

            }
            .down-m2-val{
              color: #666666;
              font-size: 16px;
              text-indent: 30px;
              line-height: 24px;
              padding: 0 60px 0 10px;

            }

          }

        }
        .down-m3{
          font-size: 24px;
          font-weight: 550;
          color: #333333;
          margin-bottom: 50px;
        }
        .slideshow{
          width: 1200px;
          margin: 0 auto;
          .slideshow-top{
            color: #333333;
            font-size: 18px;
            line-height: 30px;

          }
          .slideshow-tops{
            padding-bottom: 30px;
          }
          .carousel1{
            background-image: url("./../static/img/contact/imgs1.png");
            background-size: 900px 600px;
            object-fit: cover;
          }
          .carousel2{
            background-image: url("./../static/img/contact/imgs2.png");
            background-size: 900px 600px;
            object-fit: cover;
          }
          .carousel3{
            background-image: url("./../static/img/contact/imgs3.png");
            background-size: 900px 600px;
            object-fit: cover;
          }
          .carousel4{
            background-image: url("./../static/img/contact/imgs4.png");
            background-size: 900px 600px;
            object-fit: cover;
          }

          ///deep/ element.style {
          //  transform: translateX(10px) scale(0.83) !important;
          //}
          /deep/ .el-carousel__item.is-active {
            position: relative;
            top: 10px;
            left: -300px;
          }
          /deep/ .el-carousel__indicators--outside{
            visibility: hidden;
          }
          /deep/ .is-in-stage{
            position: absolute;
            top: 0;
            left: -60px;
            margin-right: 70px;
          }
          /deep/ .el-carousel__item--card{
            width: 900px !important;
            margin-right: 0;

          }
        }
      }



    }

  }
</style>
